 (function() {
	var loginForm, registerForm;

	var login = function() {
    	if (loginForm.valid()) {
    		var password = $('input[name="password"]', loginForm);
			if (password.val().length > 0) password.val(CryptoJS.SHA1(password.val()));

    		$.ajax({
				url: laroute.action('App\Http\Controllers\Auth\AuthController@frontLogin'),
				type : 'POST',
				dataType: 'json',
				data: loginForm.serializeObject(),
				success : function(response) {
					if (response.status == 'ok') window.location.reload();
					else addFormErrors(response, loginForm);
				},
				error : function(response) {
					addFormErrors(response.responseText, loginForm);
				}
			});
    		
    	}
	};

	var addFormErrors = function(data, form) {
		try { data = JSON.parse(data); } catch(e) {}
		$.each(data, function(index, value){
		    $('input[name="' + index + '"]', form).closest('.form-group').addClass('has-error').append('<span class="help-block">' + ((Array.isArray(value))?value[0]:value) + '</span>');
		});
	};
	
	var register = function() {
    	if (registerForm.valid()) {
        	var data = registerForm.serializeObject();
        	data.password = new String(CryptoJS.SHA1(data.password));
        	data.password_confirmation = new String(CryptoJS.SHA1(data.password_confirmation));
        	
    		$.ajax({
				url: laroute.action('App\Http\Controllers\Auth\AuthController@frontRegister'),
				type : 'POST',
				dataType: 'json',
				data: data,
				success : function(response) {
					window.location.reload();
				},
				error : function(response) {
    				
					addFormErrors(response.responseText, registerForm);
				}
			});
    		
    	}
	};
	
	window.Auth = {
		init : function() {        		
			registerForm = $('#register-form');
			registerForm.validate({rules: {name: {required: true}, email: {required: true, email: true}, password : {required: true}, password_confirmation: {required: true, equalTo: '#register-form input[name="password"]'}}});
			
			loginForm = $('#login-form');
			loginForm.validate({rules: {email: {required: true, email: true}, password : {required: true}}});
			
			$('#login-link', loginForm).click(login);
			$('#register-link', registerForm).click(register);

		},
		show : function() {
			loginForm.closest('.login-screen').removeClass('hidden');
		},
		register : function() {
			registerForm.closest('.login-screen').removeClass('hidden');
		},
		close : function() {
			$('.login-screen').addClass('hidden');
			
		}
	};
	
	Auth.init();
		
})();