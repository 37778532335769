/*

Ideavity - 08/2016
http://www.ideavity.com

*/
(function() {

	var pos = 0;
	var galleryIdx = 0;

	var galleries = [];

	function init(gs){
		galleries = gs;
	}

	function show(g){
		galleryIdx = g;
		$(".lightbox_idv").show();

		pos = 0;

		draw();
	}

	function draw(){
		var media = galleries[galleryIdx][pos];

		$("#lightbox_idv .lightbox-title").html(media.title);

		$("#lightbox_idv .loader").show();
		$("#lightbox_idv .lightbox-media-item").remove();

		if(media.type=="video/mp4")
			showVideo(media);
		else showImage(media);
	}

	function showImage(media) {
		
		$("#lightbox_idv .lightbox-media").prepend("<img class='lightbox-media-item' src='"+media.url+"' alt='"+media.title+"'/>");
		$("#lightbox_idv .lightbox-media img").on("load",function(){
			
			$("#lightbox_idv .loader").hide();
			// $("#lightbox_idv .container").width($("#lightbox_idv .container img").width());
			// $("#lightbox_idv .container").height($("#lightbox_idv .container img").height()); 

		})
	}

	function showVideo(media) {

		var width = Math.min($(window).width()-120,640);
		var height = Math.min($(window).height()-100,480);

		var getUrl = window.location;
		var baseUrl = getUrl .protocol + "//" + getUrl.host;
		
		var html = '<video id="lightbox-video" class="lightbox-media-item video-js vjs-default-skin vjs-big-play-centered"\
					preload="auto" width="'+width+'" height="'+height+'" poster="">\
		 			<source src="' + baseUrl + media.url +'" type="video/mp4" />\
		</video>';

		$("#lightbox_idv .lightbox-media").prepend(html);

		document.getElementById('lightbox-video').addEventListener('loadedmetadata', function() {
		  this.currentTime = 1;
		  this.play();
		  this.pause();
		}, false);

		var options={"controls": true, "autoplay": false, "controlBar": { "Fullscreen": false }};
		videojs($("#lightbox-video")[0], options);

		$(".vjs-play-control").css('bottom',($('.lightbox-media-item').height()/2)-30);
		

		$("#lightbox_idv .loader").hide();
	}

	function next(){
		if(pos<galleries[galleryIdx].length-1)pos++;
		else pos = 0;
		draw();
	}

	function previous(){
		if(pos>0)pos--;
		else pos = galleries[galleryIdx].length-1;
		draw();
	}

	function close() {
		$(".lightbox_idv").hide();
		$(".lightbox_idv video").remove();
	}

  	var L = {
  		init: init,
  		show: show,
  		close: close,
  		next: next,
  		previous: previous
  	};


	window.Lightbox = L;

})();
